<template>
  <div id="SOCIAL_FORM" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
      @hidden="hidden"
      @ok="ok"
      scrollable
    >

      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ labelTitle }}
        </h5>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <b-form-group>
        <label class="mr-1">{{ $t(`customer.field.socials`) }}</label>
        <b-row v-for="(social, index) in socials" v-bind:key="index">
          <b-col lg>
            <b-form-group>
              <b-input-group>
                <b-input-group-prepend>
                <b-form-select v-model="social.kind" :options="optionSocials" :disabled="isReadOnly">
                    <template slot="first">
                      <option :value="null" disabled>{{ $t('customer.select_socialnet_type') }}</option>
                    </template>
                </b-form-select>
                </b-input-group-prepend>
                <b-form-input type="text"
                  v-model="social.data"
                  :readonly="isReadOnly"
                  trim></b-form-input>
                <b-input-group-append>
                  <b-button :id="`SOCIAL_BTN_ADD_${index}`" variant="secondary" @click="fieldItemAdd(index, {kind: null, data: null})"><font-awesome-icon :icon="['far', 'plus']"/></b-button>
                  <b-popover boundary="window" :target="`SOCIAL_BTN_ADD_${index}`" triggers="hover" placement="top">
                    {{ $t('button.add') }}
                  </b-popover>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button :id="`SOCIAL_BTN_REMOVE_${index}`" variant="secondary" @click="fieldItemRemove(index, {kind: null, data: null})"><font-awesome-icon :icon="['far', 'trash-can']"/></b-button>
                  <b-popover boundary="window" :target="`SOCIAL_BTN_REMOVE_${index}`" triggers="hover" placement="top">
                    {{ $t('button.remove') }}
                  </b-popover>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <template v-slot:modal-footer="{ ok, cancel }">
        <template v-if="canEdit()">
          <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { objectClone } from '@/helpers'
import { socialTypes } from '@/selectOptions'

export default {
  name: 'SocialsModal',
  components: {
    
  },
  props: {
    title:     { type: String,   default: null }
    , readOnly:  { type: Boolean,  default: false }
    , show:      { type: Boolean, required: true }
    , permissionName: { type: String, required: true }
    , socialList: { type: Array, default: () => [] }
  },
  data() {
    return {
      editable: false
      , modalShow: false
      , socials: []
      
    }
  },
  created() {
    this.optionSocials = socialTypes
    this.prepareValue(this.socialList)
  },
  mounted() {
    this.modalShow = this.show
  },
  beforeDestroy() {
    this.optionSocials = null
  },
  computed: {
    isReadOnly() {
      return !this.editable && this.readOnly
    }
    , labelTitle() {
      return this.title? this.title: this.$t('badge.editSocials')
    }
   
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.modalShow = newValue
      }
      if (newValue) {
        this.prepareValue(this.socialList)
      }
    }
  },
  methods: {
    ok() {
      // don't send null values for socials and socials
      if (this.socials.length > 0 && this.socials[0] === null || this.socials[0].data === null || this.socials[0].data.trim().length == 0) {
        this.socials.splice(0, this.socials.length)
      }

      this.$emit('ok', { socialList: objectClone(this.socials) })
    },
    hidden() {
      this.$emit('cancel')
      this.$emit('update:show', false)
    },
    prepareValue(newValue) {
      if (Array.isArray(newValue) && newValue.length > 0) {
        this.socials.splice(0, this.socials.length, ...newValue)
      } else {
        this.socials.splice(0, this.socials.length, { kind: null, data: null })
      }
    },
    fieldItemAdd(index, _default) {
      this.socials.splice(index+1, 0, _default)
    },
    fieldItemRemove(index, _default) {
      this.socials.splice(index, 1)
      if (this.socials.length == 0) {
        this.socials.push(_default)
      }
    },
    
  }
}
</script>
<style>
</style>